import { tracingContextFromHeaders, logger, dropUndefinedKeys } from '@sentry/utils';
import { DEBUG_BUILD } from '../debug-build.js';
import { getCurrentScope, withScope } from '../exports.js';
import { getCurrentHub } from '../hub.js';
import { handleCallbackErrors } from '../utils/handleCallbackErrors.js';
import { hasTracingEnabled } from '../utils/hasTracingEnabled.js';
import { spanToJSON, spanTimeInputToSeconds } from '../utils/spanUtils.js';

/**
 * Wraps a function with a transaction/span and finishes the span after the function is done.
 *
 * Note that if you have not enabled tracing extensions via `addTracingExtensions`
 * or you didn't set `tracesSampleRate`, this function will not generate spans
 * and the `span` returned from the callback will be undefined.
 *
 * This function is meant to be used internally and may break at any time. Use at your own risk.
 *
 * @internal
 * @private
 *
 * @deprecated Use `startSpan` instead.
 */
function trace(context, callback,
// eslint-disable-next-line @typescript-eslint/no-empty-function
onError = () => {},
// eslint-disable-next-line @typescript-eslint/no-empty-function
afterFinish = () => {}) {
  // eslint-disable-next-line deprecation/deprecation
  const hub = getCurrentHub();
  const scope = getCurrentScope();
  // eslint-disable-next-line deprecation/deprecation
  const parentSpan = scope.getSpan();
  const ctx = normalizeContext(context);
  const activeSpan = createChildSpanOrTransaction(hub, parentSpan, ctx);

  // eslint-disable-next-line deprecation/deprecation
  scope.setSpan(activeSpan);
  return handleCallbackErrors(() => callback(activeSpan), error => {
    activeSpan && activeSpan.setStatus('internal_error');
    onError(error, activeSpan);
  }, () => {
    activeSpan && activeSpan.end();
    // eslint-disable-next-line deprecation/deprecation
    scope.setSpan(parentSpan);
    afterFinish();
  });
}

/**
 * Wraps a function with a transaction/span and finishes the span after the function is done.
 * The created span is the active span and will be used as parent by other spans created inside the function
 * and can be accessed via `Sentry.getSpan()`, as long as the function is executed while the scope is active.
 *
 * If you want to create a span that is not set as active, use {@link startInactiveSpan}.
 *
 * Note that if you have not enabled tracing extensions via `addTracingExtensions`
 * or you didn't set `tracesSampleRate`, this function will not generate spans
 * and the `span` returned from the callback will be undefined.
 */
function startSpan(context, callback) {
  const ctx = normalizeContext(context);
  return withScope(context.scope, scope => {
    // eslint-disable-next-line deprecation/deprecation
    const hub = getCurrentHub();
    // eslint-disable-next-line deprecation/deprecation
    const parentSpan = scope.getSpan();
    const activeSpan = createChildSpanOrTransaction(hub, parentSpan, ctx);
    // eslint-disable-next-line deprecation/deprecation
    scope.setSpan(activeSpan);
    return handleCallbackErrors(() => callback(activeSpan), () => {
      // Only update the span status if it hasn't been changed yet
      if (activeSpan) {
        const {
          status
        } = spanToJSON(activeSpan);
        if (!status || status === 'ok') {
          activeSpan.setStatus('internal_error');
        }
      }
    }, () => activeSpan && activeSpan.end());
  });
}

/**
 * @deprecated Use {@link startSpan} instead.
 */
const startActiveSpan = startSpan;

/**
 * Similar to `Sentry.startSpan`. Wraps a function with a transaction/span, but does not finish the span
 * after the function is done automatically. You'll have to call `span.end()` manually.
 *
 * The created span is the active span and will be used as parent by other spans created inside the function
 * and can be accessed via `Sentry.getActiveSpan()`, as long as the function is executed while the scope is active.
 *
 * Note that if you have not enabled tracing extensions via `addTracingExtensions`
 * or you didn't set `tracesSampleRate`, this function will not generate spans
 * and the `span` returned from the callback will be undefined.
 */
function startSpanManual(context, callback) {
  const ctx = normalizeContext(context);
  return withScope(context.scope, scope => {
    // eslint-disable-next-line deprecation/deprecation
    const hub = getCurrentHub();
    // eslint-disable-next-line deprecation/deprecation
    const parentSpan = scope.getSpan();
    const activeSpan = createChildSpanOrTransaction(hub, parentSpan, ctx);
    // eslint-disable-next-line deprecation/deprecation
    scope.setSpan(activeSpan);
    function finishAndSetSpan() {
      activeSpan && activeSpan.end();
    }
    return handleCallbackErrors(() => callback(activeSpan, finishAndSetSpan), () => {
      // Only update the span status if it hasn't been changed yet, and the span is not yet finished
      if (activeSpan && activeSpan.isRecording()) {
        const {
          status
        } = spanToJSON(activeSpan);
        if (!status || status === 'ok') {
          activeSpan.setStatus('internal_error');
        }
      }
    });
  });
}

/**
 * Creates a span. This span is not set as active, so will not get automatic instrumentation spans
 * as children or be able to be accessed via `Sentry.getSpan()`.
 *
 * If you want to create a span that is set as active, use {@link startSpan}.
 *
 * Note that if you have not enabled tracing extensions via `addTracingExtensions`
 * or you didn't set `tracesSampleRate` or `tracesSampler`, this function will not generate spans
 * and the `span` returned from the callback will be undefined.
 */
function startInactiveSpan(context) {
  if (!hasTracingEnabled()) {
    return undefined;
  }
  const ctx = normalizeContext(context);
  // eslint-disable-next-line deprecation/deprecation
  const hub = getCurrentHub();
  const parentSpan = context.scope ?
  // eslint-disable-next-line deprecation/deprecation
  context.scope.getSpan() : getActiveSpan();
  return parentSpan ?
  // eslint-disable-next-line deprecation/deprecation
  parentSpan.startChild(ctx) :
  // eslint-disable-next-line deprecation/deprecation
  hub.startTransaction(ctx);
}

/**
 * Returns the currently active span.
 */
function getActiveSpan() {
  // eslint-disable-next-line deprecation/deprecation
  return getCurrentScope().getSpan();
}

/**
 * Continue a trace from `sentry-trace` and `baggage` values.
 * These values can be obtained from incoming request headers,
 * or in the browser from `<meta name="sentry-trace">` and `<meta name="baggage">` HTML tags.
 *
 * The callback receives a transactionContext that may be used for `startTransaction` or `startSpan`.
 */
function continueTrace({
  sentryTrace,
  baggage
}, callback) {
  const currentScope = getCurrentScope();
  const {
    traceparentData,
    dynamicSamplingContext,
    propagationContext
  } = tracingContextFromHeaders(sentryTrace, baggage);
  currentScope.setPropagationContext(propagationContext);
  if (DEBUG_BUILD && traceparentData) {
    logger.log(`[Tracing] Continuing trace ${traceparentData.traceId}.`);
  }
  const transactionContext = {
    ...traceparentData,
    metadata: dropUndefinedKeys({
      dynamicSamplingContext: traceparentData && !dynamicSamplingContext ? {} : dynamicSamplingContext
    })
  };
  if (!callback) {
    return transactionContext;
  }
  return callback(transactionContext);
}
function createChildSpanOrTransaction(hub, parentSpan, ctx) {
  if (!hasTracingEnabled()) {
    return undefined;
  }
  return parentSpan ?
  // eslint-disable-next-line deprecation/deprecation
  parentSpan.startChild(ctx) :
  // eslint-disable-next-line deprecation/deprecation
  hub.startTransaction(ctx);
}

/**
 * This converts StartSpanOptions to TransactionContext.
 * For the most part (for now) we accept the same options,
 * but some of them need to be transformed.
 *
 * Eventually the StartSpanOptions will be more aligned with OpenTelemetry.
 */
function normalizeContext(context) {
  if (context.startTime) {
    const ctx = {
      ...context
    };
    ctx.startTimestamp = spanTimeInputToSeconds(context.startTime);
    delete ctx.startTime;
    return ctx;
  }
  return context;
}
export { continueTrace, getActiveSpan, startActiveSpan, startInactiveSpan, startSpan, startSpanManual, trace };
