import { InjectionToken } from '@angular/core';

export interface LoggingService {
  log(message: string, payload?: Record<string, unknown> | number | string | boolean): void;

  warn(message: string, payload?: Record<string, unknown> | number | string | boolean): void;

  error(message: string, payload?: Record<string, unknown> | number | string | boolean): void;
}

export interface LoggingConfiguration {
  release?: string;
  dsn: string;
  tracingOrigins: string[];
  tracesSampleRate: number;
  showDialog: boolean;
  environment: string;
}

export const LOGGING_SERVICE = new InjectionToken<LoggingService>('LOGGING_SERVICE');
