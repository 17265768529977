import { ErrorHandler, Inject, Injectable } from '@angular/core';

import { LOGGING_SERVICE, LoggingService } from '../service/service.interface';

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
  constructor(@Inject(LOGGING_SERVICE) private loggerService: LoggingService) {
    super();
  }

  override handleError(error: any) {
    this.loggerService.error('', error.originalError || error);
  }
}
