import { ActionReducer } from '@ngrx/store';
import * as Sentry from '@sentry/core';

export function sentryBreadcrumb(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    Sentry.addBreadcrumb({
      category: 'ngrx.action',
      message: action.type,
      data: action,
      level: 'info',
    });

    return reducer(state, action);
  };
}
