import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ONBOARDING_API_CONFIG, OnboardingApiConfigInterface } from '@planone/bau365/data-access-onboarding';
import { LOGGING_SERVICE, LoggingService } from '@planone/shared/util-logging';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import * as ErrorActions from '../state/error.actions';

@Injectable()
/**
 * Intercepts responses and checks for server error status
 */
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(LOGGING_SERVICE) private loggerService: LoggingService,
    @Inject(ONBOARDING_API_CONFIG)
    private onboarding_api_config: OnboardingApiConfigInterface,
    private readonly store: Store
  ) {}

  /**
   * @param req
   * @param next
   * @returns
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(null, (err) => {
        if (err instanceof HttpErrorResponse) {
          const apiUrl = this.onboarding_api_config.fairDataGatewayUrl;
          const checkStatusCodes = [0, 502, 503, 504];
          if (checkStatusCodes.includes(err.status) && err.url?.includes(apiUrl)) {
            this.loggerService.error(err.message, err.error);
            this.store.dispatch(ErrorActions.globalErrorWasThrown({}));
          }
        }
      })
    );
  }
}
