import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { Bau365UiShellModule, PageNotFoundComponent } from '@planone/bau365/ui-shell';

import { ErrorEffects } from './state/error.effects';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: PageNotFoundComponent,
        data: {
          analyticsPageType: 'not-found',
        },
      },
    ]),
    Bau365UiShellModule,
    EffectsModule.forFeature([ErrorEffects]),
  ],
})
export class Bau365FeatureErrorsModule {}
