import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from '@planone/bau365/util-notification';
import { SnackBarType } from '@planone/shared/util-interfaces';
import { tap } from 'rxjs/operators';

import * as ErrorActions from './error.actions';

@Injectable()
export class ErrorEffects {
  globalError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ErrorActions.globalErrorWasThrown),
        tap((action) => {
          this.notificationService.projectContent(
            action.message ? action.message : 'Ein Fehler ist aufgetreten.',
            SnackBarType.failure,
            'reload'
          );
        })
      ),
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private readonly notificationService: NotificationService) {}
}
