import { Injectable } from '@angular/core';

import { LoggingService } from './service.interface';

@Injectable({
  providedIn: 'root',
})
export class ConsoleLoggingService implements LoggingService {
  public error(message: string, payload?: Record<string, unknown> | number | string | boolean) {
    console.error(message, payload);
  }

  public log(message: string, payload?: Record<string, unknown> | number | string | boolean) {
    console.info(message, payload);
  }

  public warn(message: string, payload?: Record<string, unknown> | number | string | boolean) {
    console.warn(message, payload);
  }
}
