import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as SentryIvy from '@sentry/angular-ivy';
import * as SentryBrowser from '@sentry/browser';
import * as SentryCore from '@sentry/core';

import { LoggingService } from './service.interface';

import { LoggingConfig } from '../config/sentry.config';

@Injectable({
  providedIn: 'root',
})
export class SentryLoggingService implements LoggingService {
  platformId: any;

  constructor(loggerConfig: LoggingConfig) {
    this.platformId = inject(PLATFORM_ID);

    SentryIvy.init({
      dsn: loggerConfig.dsn,
      release: loggerConfig.release,
      environment: loggerConfig.environment,
      integrations: [
        new SentryBrowser.BrowserTracing({
          tracePropagationTargets: loggerConfig.tracingOrigins,
          routingInstrumentation: SentryIvy.routingInstrumentation,
        }),
      ],
      tracesSampleRate: loggerConfig.tracesSampleRate,
    });
  }

  public error(message: string, payload?: Record<string, unknown> | number | string | boolean) {
    if (
      payload &&
      ((isPlatformBrowser(this.platformId) && payload instanceof ErrorEvent) || payload instanceof Error)
    ) {
      SentryCore.captureException(payload);
      if (message) {
        SentryCore.captureMessage(message);
      }
    } else {
      const suffix = payload != null ? ' ' + payload.toString() : '';
      SentryCore.captureException(message + suffix);
    }
  }

  public log(message: string, payload?: Record<string, unknown> | number | string | boolean) {
    const suffix = payload != null ? ' ' + payload.toString() : '';
    SentryCore.captureMessage(message + suffix);
  }

  public warn(message: string, payload?: Record<string, unknown> | number | string | boolean) {
    const suffix = payload != null ? ' ' + payload.toString() : '';
    SentryCore.captureMessage(message + suffix);
  }
}
